import * as REST from '../../api/api'
import {
  GET_CURRENT_LOCATION,
  SET_CENTER_COORDINATE,
  GET_CAR_WASHES_REQUEST,
  GET_CAR_WASHES_SUCCESS,
  GET_CAR_WASHES_FAILED,
  SET_DEST_COORDIATE,
  RESET_RESULT,
  SET_CURRENT_WASH,
  SET_FILTERS,
  SET_MODAL_VISIBLE,
  SET_CENTER_ADDRESS,
} from '../types'

export const setCenterCoordinate = (coordinate) => ({
  type: SET_CENTER_COORDINATE,
  payload: coordinate,
})

export const getCurrentLocation = () => ({ type: GET_CURRENT_LOCATION })

export const initializeSearchResult = () => ({ type: RESET_RESULT })

export const setFilterResult = (data) => ({ type: SET_FILTERS, payload: data })

export const getCarWashes = ({ lat, lng }) => async (dispatch) => {
  try {
    dispatch({ type: GET_CAR_WASHES_REQUEST })
    const result = await REST.getCarWashes(lat, lng)
    dispatch({ type: GET_CAR_WASHES_SUCCESS, payload: result })
  } catch (error) {
    dispatch({ type: GET_CAR_WASHES_FAILED, payload: error })
  }
}

export const setDestCoordinate = (coordinate) => ({
  type: SET_DEST_COORDIATE,
  payload: coordinate,
})

export const setCenterAddress = (address) => ({
  type: SET_CENTER_ADDRESS,
  payload: address,
})

export const setCurrentWash = (data) => ({
  type: SET_CURRENT_WASH,
  payload: data,
})

export const setVisible = (target, value) => ({
  type: SET_MODAL_VISIBLE,
  payload: { target, value },
})
