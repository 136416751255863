import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import PlacesAutoComplete from '../PlacesAutoComplete/PlacesAutoComplete'
import './LocationBox.scss'
import useCheckMobileScreen from '../../../utils/useCheckMobileScreen'
import {
  setCenterCoordinate,
  setVisible,
  setCenterAddress,
} from '../../../dux/Map/actions'

const LocationBox = () => {
  const dispatch = useDispatch()
  const isMobile = useCheckMobileScreen()
  const [address, setAddress] = useState('')

  const handleClick = async () => {
    dispatch(setVisible('search', false))
    dispatch(setVisible('miniSearch', true))
    dispatch(setVisible('filter', false))
    if (isMobile) {
      dispatch(setVisible('result', false))
    } else {
      dispatch(setVisible('result', true))
    }
    if (address) {
      const geocode = await geocodeByAddress(address)
      const latlng = await getLatLng(geocode[0])
      dispatch(setCenterCoordinate(latlng))
      dispatch(setCenterAddress(address))
    }
  }

  return (
    <div className="zip-code-wrapper">
      <div className="zip-code-container">
        <div className="zip-code-form-container">
          <span className="zip-welcome">WELCOME</span>
          <div className="zip-title">
            Find a car wash
            <br /> in second.
          </div>
          <div className="zip-code-form">
            <PlacesAutoComplete setAddress={setAddress} />
            <div className="zip-code-form-button" onClick={handleClick}>
              Show nearest car washes
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LocationBox
