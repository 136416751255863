import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDistance, convertDistance } from 'geolib'
import CustomButton from '../CustomButton/CustomButton'
import './CarWashFilter.scss'
import { setFilterResult, setVisible } from '../../../dux/Map/actions'
import CarWashFilterItem from './CarWashFilterItem'
import { checkWashStatus } from '../../../utils'

const MOCK_DATA = [
  { filter: 'opened', name: 'Currently open', count: 10 },
  { filter: 'nearest', name: 'Nearest to me', count: 5 },
]

const CarWashFilter = () => {
  const dispatch = useDispatch()
  const searchResult = useSelector((state) => state.map.searchResult)
  const currentCenter = useSelector((state) => state.map.centerCoordinate)
  const [filter, setFilter] = useState({})
  const [count, setCount] = useState({})

  const carWashDistance = (data) => {
    if (data && currentCenter) {
      const destination = {
        latitude: data.coordinates._latitude,
        longitude: data.coordinates._longitude,
      }
      const center = {
        latitude: currentCenter.lat,
        longitude: currentCenter.lng,
      }
      return (
        Math.floor(convertDistance(getDistance(center, destination), 'mi')) < 3
      )
    }
    return null
  }

  useEffect(() => {
    if (searchResult) {
      const openfiltered = searchResult.car_washes.filter((item) =>
        checkWashStatus(item.openingHours),
      )
      const nearestfiltered = searchResult.car_washes.filter((item) =>
        carWashDistance(item),
      )
      setCount({
        ...count,
        nearest: nearestfiltered.length,
        opened: openfiltered.length,
      })
    }
  }, [searchResult])

  const handleClear = () => {
    dispatch(setVisible('filter', false))
    dispatch(setVisible('search', false))
    if (searchResult && searchResult.car_washes)
      dispatch(setFilterResult(searchResult.car_washes))
    dispatch(setVisible('result', true))
    dispatch(setVisible('miniSearch', true))
  }

  const Apply = () => {
    if (searchResult) {
      let filtered = null
      if (filter.opened && filter.nearest) {
        filtered = searchResult.car_washes.filter(
          (item) => checkWashStatus(item.openingHours) && carWashDistance(item),
        )
      } else if (filter.opened && !filter.nearest) {
        filtered = searchResult.car_washes.filter((item) =>
          checkWashStatus(item.openingHours),
        )
      } else if (!filter.opened && filter.nearest) {
        filtered = searchResult.car_washes.filter((item) =>
          carWashDistance(item),
        )
      } else filtered = searchResult.car_washes
      if (filtered) {
        dispatch(setFilterResult(filtered))
        dispatch(setVisible('filter', false))
      }
    }
  }

  return (
    <div className="filter">
      <div>
        <div className="filter-header">Filter by</div>
        <div className="filter-body">
          {MOCK_DATA.map((item) => (
            <CarWashFilterItem
              key={item.filter}
              name={item.name}
              setFilter={setFilter}
              filter={item.filter}
              currentFilter={filter}
              count={count[item.filter]}
            />
          ))}
        </div>
      </div>
      <div className="filter-footer">
        <div className="filter-footer-content">
          <CustomButton
            color="#fff"
            bkColor="#006DBE"
            btnText="Apply"
            width={170}
            handleClick={Apply}
          />
          <CustomButton
            color="#006DBE"
            bkColor="#99AFBD"
            btnText="Clear"
            width={170}
            handleClick={handleClear}
          />
        </div>
      </div>
    </div>
  )
}

export default CarWashFilter
