import {
  GET_CAR_WASHES_REQUEST,
  GET_CAR_WASHES_SUCCESS,
  GET_CAR_WASHES_FAILED,
  SET_DEST_COORDIATE,
  SET_CENTER_COORDINATE,
  RESET_RESULT,
  SET_CURRENT_WASH,
  SET_FILTERS,
  SET_MODAL_VISIBLE,
  SET_CENTER_ADDRESS,
} from '../types'

const initialState = {
  searchResult: null,
  destCoordinate: null,
  currentWash: null,
  filterResult: null,
  centerCoordinate: {
    lat: 51.61356,
    lng: -0.0177,
  },
  centerAddress: '1 Norton Cl, London E4 8LS, UK',
  loading: false,
  modals: {
    search: true,
    result: false,
    filter: false,
    carWash: false,
    miniSearch: false,
  },
}

export const MapReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CAR_WASHES_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }

    case GET_CAR_WASHES_SUCCESS: {
      return {
        ...state,
        loading: false,
        searchResult: action.payload,
      }
    }

    case GET_CAR_WASHES_FAILED: {
      return {
        ...state,
        loading: false,
        searchResult: action.error,
      }
    }

    case SET_DEST_COORDIATE: {
      return {
        ...state,
        destCoordinate: action.payload,
      }
    }
    case SET_CENTER_COORDINATE: {
      return {
        ...state,
        centerCoordinate: action.payload,
      }
    }

    case RESET_RESULT: {
      return {
        ...state,
        searchResult: null,
        centerCoordinate: null,
        destCoordinate: null,
        currentWash: null,
      }
    }

    case SET_CURRENT_WASH: {
      return {
        ...state,
        currentWash: action.payload,
      }
    }

    case SET_FILTERS: {
      return {
        ...state,
        filterResult: action.payload,
      }
    }

    case SET_MODAL_VISIBLE: {
      return {
        ...state,
        modals: {
          ...state.modals,
          [action.payload.target]: action.payload.value,
        },
      }
    }

    case SET_CENTER_ADDRESS: {
      return {
        ...state,
        centerAddress: action.payload,
      }
    }

    default:
      return state
  }
}
