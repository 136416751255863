import React from 'react'
import PropTypes from 'prop-types'
import './CustomButton.scss'

const CustomButton = ({ btnText, bkColor, color, handleClick, width }) => (
  <div
    className="btn-normal"
    style={{ backgroundColor: bkColor, color, width }}
    onClick={handleClick}
  >
    {btnText}
  </div>
)

CustomButton.propTypes = {
  btnText: PropTypes.string,
  bkColor: PropTypes.string,
  color: PropTypes.string,
  handleClick: PropTypes.func,
  width: PropTypes.number,
}
export default CustomButton
