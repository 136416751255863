import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { getDistance, convertDistance } from 'geolib'
import { useDebounce } from 'use-debounce'
import carIcon from '../../../images/car.png'
import {
  setCurrentWash,
  setDestCoordinate,
  setVisible,
} from '../../../dux/Map/actions'
import './CarWashMarker.scss'

const CarWashMarker = ({ data }) => {
  const dispatch = useDispatch()
  const [isHovering, setIsHovering] = useState('')
  const currentCenter = useSelector((state) => state.map.centerCoordinate)
  const currentWash = useSelector((state) => state.map.currentWash)

  const onClickMarker = () => {
    const destination = {
      lat: data.coordinates._latitude,
      lng: data.coordinates._longitude,
    }
    dispatch(setDestCoordinate(destination))
    dispatch(setCurrentWash(data))
    dispatch(setVisible('carWash', true))
    dispatch(setVisible('result', false))
  }

  const onHoverMarker = (e) => {
    setIsHovering(e._reactName)
  }
  const debounce = useDebounce(isHovering, 300)

  useEffect(() => {
    const destination = {
      lat: data.coordinates._latitude,
      lng: data.coordinates._longitude,
    }
    if (debounce[0] === 'onMouseOver') {
      dispatch(setDestCoordinate(destination))
      dispatch(setCurrentWash(data))
    }
  }, [debounce[0]])

  const carWashDistance = () => {
    if (data && currentCenter) {
      const destination = {
        latitude: data.coordinates._latitude,
        longitude: data.coordinates._longitude,
      }
      const center = {
        latitude: currentCenter.lat,
        longitude: currentCenter.lng,
      }
      return Math.floor(convertDistance(getDistance(center, destination), 'mi'))
    }
    return null
  }

  return (
    <div
      className="wash-marker"
      onClick={onClickMarker}
      onFocus={onHoverMarker}
      onMouseOver={onHoverMarker}
      onBlur={onHoverMarker}
      onMouseOut={onHoverMarker}
    >
      <div
        className={`car-icon ${
          currentWash && currentWash.id === data.id && 'selected'
        }`}
      >
        <img className="car-img" src={carIcon} alt="car-icon" />
      </div>
      <div className="marker-wrapper ease-out">
        {data.rating && <div className="marker-rating">{data.rating}</div>}
        <div className="marker-name ease-out">{data.name}</div>
        <div className="marker-distance ease-out">
          {carWashDistance()} mile(s)
        </div>
      </div>
    </div>
  )
}
CarWashMarker.propTypes = {
  data: PropTypes.object,
}

export default CarWashMarker
