import React from 'react'
import PropTypes from 'prop-types'
import MapOutlinedIcon from '@material-ui/icons/MapOutlined'
import { useDispatch, useSelector } from 'react-redux'
import Image from '../Image/Image'
import phoneIcon from '../../../images/phone.png'
import backIcon from '../../../images/back.png'
import ratingIcon from '../../../images/rating.png'
import flagIcon from '../../../images/flag.png'
import closeIcon from '../../../images/close.png'
import fallbackIcon from '../../../images/car-wash.svg'

import './CarWash.scss'
import { setVisible } from '../../../dux/Map/actions'
import { openHours } from '../../../utils'

const CarWash = ({ hasPkgBtn = false }) => {
  const dispatch = useDispatch()
  const currentWash = useSelector((state) => state.map.currentWash)
  const name = currentWash && currentWash.name
  const telephone = currentWash && currentWash.telephone
  const logo = currentWash && currentWash.logo
  const address = currentWash && currentWash.address
  const website = currentWash && currentWash.website

  const closeCarWash = () => {
    dispatch(setVisible('carWash', false))
    dispatch(setVisible('result', true))
  }

  return (
    <div className="car-wash">
      <div className="car-wash-header">
        <a href={website} target="_blank" rel="noopener noreferrer">
          <Image
            className="car-wash--logo"
            src={logo}
            fallbackSrc={fallbackIcon}
          />
        </a>

        <div className="car-wash-close">
          <img
            src={closeIcon}
            alt="close"
            className="close-icon"
            onClick={closeCarWash}
          />
        </div>
        <div className="car-wash-actions-mobile">
          <img
            src={backIcon}
            alt="back"
            className="car-wash-actions--back"
            onClick={closeCarWash}
          />
          <div className="icon-wrapper phone-wrapper">
            <img
              src={phoneIcon}
              alt="phone"
              className="car-wash-actions--phone"
            />
          </div>
          <div className="icon-wrapper">
            <img src={ratingIcon} alt="rating" />
          </div>
          <div className="icon-wrapper">
            <img src={flagIcon} alt="flag" />
          </div>
        </div>
      </div>
      <div className="car-wash-name">
        <div className="car-wash-name--label">
          <div className="name">{name}</div>
          <div className="car-wash-name--status">
            <p>Open</p> {openHours(currentWash.openingHours)}
          </div>
        </div>
        {hasPkgBtn && <div className="car-wash-package">Packages</div>}
      </div>
      <div className="car-wash-actions">
        <div className="icon-wrapper phone-wrapper">
          <img
            src={phoneIcon}
            alt="phone"
            className="car-wash-actions--phone"
          />
        </div>
        <div className="icon-wrapper">
          <img src={ratingIcon} alt="rating" />
        </div>
        <div className="icon-wrapper">
          <MapOutlinedIcon
            style={{ fontSize: '32px', color: '#788A9A', opacity: '0.4' }}
          />
        </div>
      </div>
      <div className="divider" />
      <div className="car-wash-details">
        <p>Address</p>
        {address}
        <br />
        <br />
        {telephone && (
          <>
            <p>Telephone</p>
            {telephone}
          </>
        )}
      </div>
    </div>
  )
}
CarWash.propTypes = {
  hasPkgBtn: PropTypes.bool,
}

export default CarWash
