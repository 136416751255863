import React from 'react'
import './footer.scss'
import footerLogo from '../../../images/footerLogo.svg'
import appStoreButton from '../../../images/appstore.png'
import playStoreButton from '../../../images/playstore.png'

export const Footer = () => (
  <footer className="footer">
    <div className="footer-container">
      <img className="footer-logo" src={footerLogo} alt="AutoWash" />
      <div className="footer-buttons-section">
        <img
          className="footer-button"
          src={appStoreButton}
          alt="App Store Button"
        />
        <img
          className="footer-button"
          src={playStoreButton}
          alt="Play Store Button"
        />
      </div>
    </div>
  </footer>
)
