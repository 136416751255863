import axios from 'axios'
const { REACT_APP_API_URL } = process.env
export const rest = axios.create({
  baseURL:
    REACT_APP_API_URL !== undefined
      ? `${REACT_APP_API_URL}/api/car-wash`
      : `/api/car-wash`,
})

export const getCarWashes = (latitude, longitude) =>
  rest.get(`/coordinates/${latitude}/${longitude}`).then(({ data }) => data)
