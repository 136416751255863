import React from 'react'
import logo from '../../../images/logo.svg'
import './header.scss'

export const Header = () => (
  <header>
    <div className="wrapper">
      <img src={logo} alt="Header Logo" width={180} />
    </div>
  </header>
)
