import React from 'react'

import PlacesAutoComplete from '../PlacesAutoComplete/PlacesAutoComplete'
import './MiniSearchBar.scss'

const MiniSearchBar = () => (
  <div className="mini-search">
    <div className="mini-search-wrapper">
      <PlacesAutoComplete size="small" />
    </div>
  </div>
)

export default MiniSearchBar
