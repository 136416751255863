import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './CarWashFilter.scss'

const CarWashFilterItem = ({
  name,
  count,
  setFilter,
  filter,
  currentFilter,
}) => {
  const [selected, setSelected] = useState(false)
  const handleClick = () => {
    setSelected(!selected)
    setFilter({ ...currentFilter, [filter]: !selected })
  }
  return (
    <div className="filter-item" onClick={handleClick}>
      <div className={`filter-item-status ${selected && 'selected'}`} />
      <div className="filter-item-name">{name}</div>
      <div className="filter-item-count">{count}</div>
    </div>
  )
}
CarWashFilterItem.propTypes = {
  name: PropTypes.string,
  count: PropTypes.number,
  setFilter: PropTypes.func,
  filter: PropTypes.string,
  currentFilter: PropTypes.object,
}
export default CarWashFilterItem
