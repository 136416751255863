export const loadScript = (src, position, id, cb) => {
  if (!position) {
    return
  }

  const script = document.createElement('script')
  script.setAttribute('async', '')
  script.setAttribute('id', id)

  if (cb) {
    script.onload = cb
  }

  script.src = src
  position.appendChild(script)
}

export const checkWashStatus = (data) => {
  const d = new Date()
  const day = d.getDay()
  const currentTime = `${d.getHours()}${d.getMinutes()}`
  if (!data) return true
  if (data[day]) {
    if (
      data[day].open &&
      data[day].open.time &&
      data[day].close &&
      data[day].close.time
    ) {
      return (
        data[day].open.time <= currentTime &&
        data[day].close.time >= currentTime
      )
    }
  }
  return true
}

export const openHours = (data) => {
  const d = new Date()
  const day = d.getDay()
  if (!data) return 'unkown'
  if (data[day]) {
    if (
      data[day].open &&
      data[day].open.time &&
      data[day].close &&
      data[day].close.time
    ) {
      return `${data[day].open.time} to ${data[day].close.time}`
    }
  }
  return 'unkown'
}
