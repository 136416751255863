import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import rightIcon from '../../../images/right-arrow.png'
import './CarWashListItem.scss'
import {
  setCurrentWash,
  setDestCoordinate,
  setVisible,
} from '../../../dux/Map/actions'
import { checkWashStatus } from '../../../utils'

const CarWashListItem = ({ rating, name, distance, address, data }) => {
  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch(setCurrentWash(data))
    const destination = {
      lat: data.coordinates._latitude,
      lng: data.coordinates._longitude,
    }
    dispatch(setDestCoordinate(destination))
    dispatch(setVisible('carWash', true))
    dispatch(setVisible('result', false))
  }
  return (
    <div className="list-item" onClick={handleClick}>
      <div className={`rating ${!rating && 'none'}`}>{rating || '*'}</div>
      <div className="content">
        <div className="top">
          <div className="name">{name}</div>
          <div
            className={`status ${
              checkWashStatus(data.openingHours) ? 'open' : 'closed'
            }`}
          >
            {checkWashStatus(data.openingHours) ? 'Open' : 'Closed'}
          </div>
        </div>
        <div className="bottom">
          <div className="distance">
            {distance} Mile{distance > 1 && 's'}&nbsp;
          </div>
          <div className="address">
            &bull;&nbsp;
            {address}
          </div>
        </div>
      </div>
      <img className="right-arrow" src={rightIcon} alt="right-arrow" />
    </div>
  )
}
CarWashListItem.propTypes = {
  rating: PropTypes.number,
  name: PropTypes.string,
  distance: PropTypes.any,
  address: PropTypes.string,
  data: PropTypes.object,
}
export default CarWashListItem
