export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION'
export const GET_CURRENT_LOCATION = 'GET_CURRENT_LOCATION'
export const GET_CAR_WASHES_REQUEST = 'GET_CAR_WASHES_REQUEST'
export const GET_CAR_WASHES_SUCCESS = 'GET_CAR_WASHES_SUCCESS'
export const GET_CAR_WASHES_FAILED = 'GET_CAR_WASHES_SUCCESS'
export const SET_DEST_COORDIATE = 'SET_DEST_COORDIATE'
export const RESET_RESULT = 'RESET_RESULT'
export const SET_CENTER_COORDINATE = 'SET_CENTER_COORDINATE'
export const SET_CURRENT_WASH = 'SET_CURRENT_WASH'
export const SET_FILTERS = 'SET_FILTERS'
export const SET_MODAL_VISIBLE = 'SET_MODAL_VISIBLE'
export const SET_CENTER_ADDRESS = 'SET_CENTER_ADDRESS'
