export default {
  style: [
    {
      featureType: 'landscape',
      elementType: 'all',
      stylers: [
        {
          color: '#f2f2f2',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry.fill',
      stylers: [
        {
          visibility: 'on',
        },
        {
          saturation: '-1',
        },
        {
          lightness: '100',
        },
        {
          color: '#c8e6d6',
        },
        {
          gamma: '1.67',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          visibility: 'simplified',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'all',
      stylers: [
        {
          saturation: -100,
        },
        {
          lightness: 40,
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels.text',
      stylers: [
        {
          weight: '0.01',
        },
        {
          invert_lightness: true,
        },
        {
          lightness: '28',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'all',
      stylers: [
        {
          visibility: 'simplified',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'all',
      stylers: [
        {
          visibility: 'on',
        },
        {
          color: '#8cdafa',
        },
        {
          saturation: '100',
        },
        {
          lightness: '0',
        },
        {
          gamma: '1',
        },
        {
          weight: '1',
        },
      ],
    },
  ],
}
