import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import parse from 'autosuggest-highlight/parse'
import throttle from 'lodash/throttle'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import GpsFixedIcon from '@material-ui/icons/GpsFixed'
import {
  setCenterAddress,
  setCenterCoordinate,
  setVisible,
} from '../../../dux/Map/actions'
import searchIcon from '../../../images/fe-search.png'

const autocompleteService = { current: null }

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  popper: {
    maxHeight: 200,
  },
  location: {
    '& > .MuiFormLabel-root.Mui-focused': {
      color: '#6D8193',
    },
  },
  locationSmall: {
    '& > .MuiFormLabel-root.Mui-focused': {
      color: '#6D8193',
    },

    '& > label + .MuiInput-formControl': {
      marginTop: 10,
    },
  },
  locationIcon: {
    display: 'flex',
    color: '#B0C2D0',
    '&:hover': { color: '#000' },
    cursor: 'pointer',
  },
  locationIconActive: {
    display: 'flex',
    cursor: 'pointer',
  },
  labelNormal: { fontFamily: 'CircularStd-Book' },
  labelSmall: {
    top: -10,
    fontFamily: 'CircularStd-Book',
    marginLeft: 40,
    fontSize: '18px',
    width: '-webkit-fill-available',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  locationInput: {
    paddingRight: 0,
    paddingBottom: 4,
  },

  locationInputSmall: {
    '&.MuiInput-underline:before': {
      border: 'none',
    },
    '&.MuiInput-underline:hover': {
      border: 'none',
    },
    '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 'none',
    },
    marginTop: 4,
  },

  searchIcon: {
    width: 24,
    height: 24,
    cursor: 'pointer',
    marginRight: 12,
  },
}))

const PlacesAutoComplete = ({ size = 'normal', setAddress }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [value, setValue] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])
  const [active, setActive] = useState(true)
  const [populateAddress, setPopulateAddress] = useState(false)
  const miniSearchVisible = useSelector((state) => state.map.modals.miniSearch)
  const centerAddress = useSelector((state) => state.map.centerAddress)

  useEffect(() => {
    if (miniSearchVisible && centerAddress) {
      setInputValue(centerAddress)
      setPopulateAddress(true)
    }
  }, [miniSearchVisible, centerAddress])

  const handleClickGPS = (e) => {
    e.preventDefault()
    if (active || miniSearchVisible) {
      setInputValue(centerAddress)
      setPopulateAddress(true)
    }
    setActive(!active)
  }

  const handleChangeMiniSearch = async (address) => {
    if (size === 'small') {
      dispatch(setVisible('search', false))
      dispatch(setVisible('result', true))
      dispatch(setVisible('carWash', false))
      if (address) {
        const geocode = await geocodeByAddress(address)
        const latlng = await getLatLng(geocode[0])
        dispatch(setCenterCoordinate(latlng))
        dispatch(setCenterAddress(address))
      }
    }
  }

  const handleSmallSearch = (e) => {
    e.preventDefault()
  }

  const inputStyle =
    size === 'normal'
      ? {
          endAdornment: (
            <GpsFixedIcon
              onClick={handleClickGPS}
              style={{ width: 20, height: 20 }}
              className={
                active ? classes.locationIcon : classes.locationIconActive
              }
            />
          ),
        }
      : {
          endAdornment: (
            <GpsFixedIcon
              style={{ width: 20, height: 20 }}
              className={
                active ? classes.locationIcon : classes.locationIconActive
              }
            />
          ),
          startAdornment: (
            <img
              onClick={handleSmallSearch}
              className={classes.searchIcon}
              src={searchIcon}
              alt="search"
            />
          ),
        }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback)
      }, 200),
    [],
  )

  useEffect(() => {
    let active = true

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }

    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return undefined
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = []

        if (value) {
          newOptions = [value]
        }

        if (results) {
          newOptions = [...newOptions, ...results]
        }

        if (populateAddress) {
          setValue(newOptions[0])
          setPopulateAddress(false)
        }

        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, fetch, populateAddress])

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      ListboxProps={{ style: { maxHeight: '200px' }, position: 'bottom-start' }}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      disableClearable
      noOptionsText="No Location"
      popupIcon=""
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options)
        setValue(newValue)
        handleChangeMiniSearch(newValue.description)
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
        if (size === 'normal') setAddress(newInputValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={inputValue ? '' : 'eg: EV1V or Startford'}
          InputLabelProps={{
            shrink: false,
            classes: {
              root:
                size === 'normal' ? classes.labelNormal : classes.labelSmall,
            },
          }}
          className={
            size === 'normal' ? classes.location : classes.locationSmall
          }
          fullWidth
          InputProps={{
            ...params.InputProps,
            ...inputStyle,
            className:
              size === 'normal'
                ? classes.locationInput
                : classes.locationInputSmall,
          }}
        />
      )}
      renderOption={(option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        )

        return (
          <Grid container alignItems="center" className={classes.popper}>
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        )
      }}
    />
  )
}

PlacesAutoComplete.propTypes = {
  size: PropTypes.string,
  setAddress: PropTypes.func,
}

export default PlacesAutoComplete
