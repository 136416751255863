import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import GoogleMapReact from 'google-map-react'
import Geocode from 'react-geocode'
import PlaceIcon from '@material-ui/icons/Place'
import GpsFixedIcon from '@material-ui/icons/GpsFixed'
import CarWashMarker from '../CarWashMarker/CarWashMarker'
import CarWash from '../CarWash/CarWash'
import mapStyle from './GoogleMapStyle'
import './GoogleMap.scss'
import { setCenterAddress, setCenterCoordinate } from '../../../dux/Map/actions'

const { REACT_APP_GOOGLE_MAPS_KEY } = process.env

const MapCenterMarker = ({ handleSetCenter, isDraging, showToolTip }) => (
  <div className="current-center-marker">
    <PlaceIcon style={{ fontSize: 50 }} />
    {!showToolTip && (
      <div
        className={`confirm-hover ${isDraging && 'ease-out'}`}
        onClick={handleSetCenter}
      >
        <GpsFixedIcon style={{ width: 20, height: 20, marginRight: '5px' }} />
        Search from here
      </div>
    )}
  </div>
)

MapCenterMarker.propTypes = {
  handleSetCenter: PropTypes.func,
  isDraging: PropTypes.bool,
  showToolTip: PropTypes.bool,
}

const GoogleMap = () => {
  const dispatch = useDispatch()
  const rendererRef = useRef(null)
  const polylineRef = useRef(null)
  const [mapReference, setMapReference] = useState(null)
  const [mapsReference, setMapsReference] = useState(null)
  const [loading, setLoading] = useState(false)
  const filteredResult = useSelector((state) => state.map.filterResult)
  const searchResult = useSelector((state) => state.map.searchResult)
  const centerCoordinate = useSelector((state) => state.map.centerCoordinate)
  const [isDraging, setIsDraging] = useState(false)
  const [mapCenterCoordinate, setMapCenterCoordinate] = useState(
    centerCoordinate,
  )
  const destCoordniate = useSelector((state) => state.map.destCoordinate)
  const carWashVisible = useSelector((state) => state.map.modals.carWash)

  const markers = filteredResult || (searchResult && searchResult.car_washes)

  useEffect(() => {
    if (destCoordniate) {
      const start = `${centerCoordinate.lat}, ${centerCoordinate.lng}`
      const end = `${destCoordniate.lat}, ${destCoordniate.lng}`
      if (!loading) handleDirections(start, end)
    } else if (polylineRef.current) {
      polylineRef.current.setMap(null)
    }
  }, [destCoordniate])

  const handleDirections = (start, end) => {
    setLoading(true)
    const directionsService = new mapsReference.DirectionsService()
    if (polylineRef.current) {
      polylineRef.current.setMap(null)
    }

    directionsService.route(
      {
        origin: start,
        destination: end,
        travelMode: 'DRIVING',
      },
      (response, status) => {
        const lineSymbol = {
          path: 'M 0,-1 0,1',
          strokeOpacity: 1,
          scale: 4,
        }
        if (status === 'OK') {
          rendererRef.current.setDirections(response)
          polylineRef.current = new mapsReference.Polyline({
            path: response.routes[0].overview_path,
            strokeOpacity: 0,
            strokeColor: '#006DBE',
            icons: [
              {
                icon: lineSymbol,
                offset: '0',
                repeat: '20px',
              },
            ],
          })
          polylineRef.current.setMap(mapReference)
          setLoading(false)
        } else {
          window.alert(`Directions request failed due to ${status}`)
          setLoading(false)
        }
      },
    )
  }
  const handleDragMap = (map) => {
    setIsDraging(true)
    if (map) {
      const center = {
        lat: map.getCenter().lat(),
        lng: map.getCenter().lng(),
      }
      setMapCenterCoordinate(center)
    }
  }

  const handleSetCenter = () => {
    if (!loading) {
      setLoading(true)
      Geocode.setApiKey(REACT_APP_GOOGLE_MAPS_KEY)
      Geocode.fromLatLng(mapCenterCoordinate.lat, mapCenterCoordinate.lng).then(
        (response) => {
          const address = response.results[0].formatted_address
          dispatch(setCenterAddress(address))
          dispatch(setCenterCoordinate(mapCenterCoordinate))
          setLoading(false)
        },
      )
    }
  }

  return (
    <>
      <div className="map-container">
        <GoogleMapReact
          center={centerCoordinate}
          defaultZoom={13}
          onDrag={(map) => handleDragMap(map)}
          onDragEnd={() => {
            setIsDraging(false)
          }}
          options={{
            styles: mapStyle.style,
            zoomControl: false,
            fullscreenControl: false,
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            setMapReference(map)
            setMapsReference(maps)
            if (!rendererRef.current) {
              rendererRef.current = new maps.DirectionsRenderer()
            }
          }}
        >
          {markers &&
            markers.length > 0 &&
            markers.map((item) => (
              <CarWashMarker
                key={item.id}
                lat={item.coordinates._latitude}
                lng={item.coordinates._longitude}
                data={item}
              />
            ))}
          <MapCenterMarker
            lat={mapCenterCoordinate.lat}
            lng={mapCenterCoordinate.lng}
            handleSetCenter={handleSetCenter}
            showToolTip={centerCoordinate === mapCenterCoordinate}
            isDraging={isDraging}
          />
        </GoogleMapReact>
      </div>
      {carWashVisible && (
        <div className="car-wash-wrapper">
          <CarWash />
        </div>
      )}
    </>
  )
}

export default GoogleMap
