import React, { useEffect, useRef, useState } from 'react'
import FontFaceObserver from 'fontfaceobserver'
import { useDispatch, useSelector } from 'react-redux'
import IdleTimer from 'react-idle-timer'
import { Header } from './components/Header/Header'
import { Footer } from './components/Footer/Footer'
import LocationBox from './components/LocationBox/LocationBox'
import CarWashResult from './components/CarWashResult/CarWashResult'
import CarWashFilter from './components/CarWashFilter/CarWashFilter'
import GoogleMap from './components/GoogleMap/GoogleMap'
import useCheckMobileScreen from '../utils/useCheckMobileScreen'
import {
  getCarWashes,
  setCurrentWash,
  setDestCoordinate,
  setVisible,
} from '../dux/Map/actions'
import filterIcon from '../images/filter.png'
import { loadScript } from '../utils/index'
import './home.scss'
import MiniSearchBar from './components/MiniSearchBar/MiniSearchBar'
import loadingIcon from '../images/loading.gif'

const { REACT_APP_GOOGLE_MAPS_KEY } = process.env

export const Home = () => {
  const dispatch = useDispatch()
  const isMobile = useCheckMobileScreen()
  const idleTimer = useRef(null)
  const [myTimer, setMyTimer] = useState(null)
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const searchVisible = useSelector((state) => state.map.modals.search)
  const resultVisible = useSelector((state) => state.map.modals.result)
  const filterVisible = useSelector((state) => state.map.modals.filter)
  const miniSearchVisible = useSelector((state) => state.map.modals.miniSearch)
  const centerCoordinate = useSelector((state) => state.map.centerCoordinate)
  const isDataFetching = useSelector((state) => state.map.loading)
  const searchResult = useSelector((state) => state.map.searchResult)
  const [fontLoaded, setFontLoaded] = useState(false)

  useEffect(() => {
    const font = new FontFaceObserver('CircularStd-Medium')
    const bold = new FontFaceObserver('CircularStd-Bold')
    const book = new FontFaceObserver('CircularStd-Book')
    bold.load().then(() => {})
    book.load().then(() => {})
    font.load().then(() => {
      setFontLoaded(true)
    })
  }, [fontLoaded])

  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }

    if (document.querySelector('#google-maps')) {
      setScriptLoaded(true)
    } else {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_MAPS_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
        () => setScriptLoaded(true),
      )
    }
  }, [])

  useEffect(() => {
    if (centerCoordinate) {
      dispatch(getCarWashes(centerCoordinate))
    }
  }, [centerCoordinate])

  const handleOnAction = () => {
    clearInterval(myTimer)
  }

  const handleOnActive = () => {
    clearInterval(myTimer)
  }

  const handleOnIdle = () => {
    const timer = setInterval(() => {
      if (searchResult && searchResult.car_washes) {
        const carWash =
          searchResult.car_washes[
            Math.floor(Math.random() * searchResult.car_washes.length)
          ]
        dispatch(setCurrentWash(carWash))
        const destination = {
          lat: carWash.coordinates._latitude,
          lng: carWash.coordinates._longitude,
        }
        dispatch(setDestCoordinate(destination))
      }
    }, 5000)
    setMyTimer(timer)
  }

  return (
    <>
      <IdleTimer
        ref={idleTimer}
        timeout={5000}
        onIdle={handleOnIdle}
        onActive={handleOnActive}
        onAction={handleOnAction}
        debounce={250}
      />
      <Header />
      <div data-testid="location-box">
        {scriptLoaded && (
          <section className="map-section">
            <GoogleMap />
            {fontLoaded && searchVisible && <LocationBox />}
          </section>
        )}
        {!isDataFetching ? (
          <>
            {resultVisible && (
              <div className="carwash-result-wrapper">
                <CarWashResult />
              </div>
            )}
            {miniSearchVisible && <MiniSearchBar />}
            {filterVisible ? (
              <div className="carwash-result-wrapper">
                <CarWashFilter />
              </div>
            ) : (
              !searchVisible && (
                <div
                  className="filter-toggler"
                  onClick={() => {
                    if (isMobile) {
                      dispatch(setVisible('result', true))
                    } else {
                      dispatch(setVisible('filter', true))
                    }
                    dispatch(setVisible('carWash', false))
                    dispatch(setVisible('search', false))
                  }}
                >
                  <img src={filterIcon} width={21} height={23} alt="filter" />
                </div>
              )
            )}
          </>
        ) : (
          <div className="loading-wrapper">
            <div className="loading">
              <img src={loadingIcon} width={50} height={50} alt="loading" />
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  )
}
