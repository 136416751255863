import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDistance, convertDistance } from 'geolib'
import CustomButton from '../CustomButton/CustomButton'
import CarWashListItem from '../CarWashListItem/CarWashListItem'
import './CarWashResult.scss'
import {
  initializeSearchResult,
  setCurrentWash,
  setDestCoordinate,
  setVisible,
} from '../../../dux/Map/actions'

const CarWashResult = () => {
  const dispatch = useDispatch()
  const [sortedResult, setSortedResult] = useState(null)
  const filteredResult = useSelector((state) => state.map.filterResult)
  const searchResult = useSelector((state) => state.map.searchResult)
  const centerCoordinate = useSelector((state) => state.map.centerCoordinate)

  useEffect(() => {
    const result = filteredResult || (searchResult && searchResult.car_washes)
    if (result && result.length > 0) {
      const updatedResult = result
      updatedResult.sort((a, b) => carWashDistance(a) - carWashDistance(b))
      setSortedResult(updatedResult)
    }
  }, [filteredResult, searchResult])

  useEffect(() => {
    if (sortedResult) {
      const destination = {
        lat: sortedResult[0].coordinates._latitude,
        lng: sortedResult[0].coordinates._longitude,
      }
      setDestCoordinate(destination)
      dispatch(setCurrentWash(sortedResult[0]))
    }
  }, [sortedResult])

  const handleClick = () => {
    dispatch(setVisible('result', false))
    dispatch(setVisible('filter', true))
  }

  const handleClear = () => {
    dispatch(setVisible('result', false))
    dispatch(setVisible('search', true))
    dispatch(setVisible('miniSearch', false))
    dispatch(initializeSearchResult())
  }

  const carWashDistance = (dest) => {
    if (centerCoordinate) {
      const center = {
        latitude: centerCoordinate.lat,
        longitude: centerCoordinate.lng,
      }
      const destination = {
        latitude: dest.coordinates._latitude,
        longitude: dest.coordinates._longitude,
      }
      return Math.floor(convertDistance(getDistance(center, destination), 'mi'))
    }
    return ''
  }

  return (
    <div className="result">
      <div>
        <div className="result-header">Nearest car washes</div>
        <div className="result-body">
          {sortedResult &&
            sortedResult.map((item) => (
              <CarWashListItem
                key={item.id}
                rating={item.rating}
                name={item.name}
                distance={carWashDistance(item)}
                address={item.address}
                data={item}
              />
            ))}
        </div>
      </div>
      <div className="result-footer">
        <div className="result-footer-content">
          <CustomButton
            color="#fff"
            bkColor="#006DBE"
            btnText="Filter"
            width={170}
            handleClick={handleClick}
          />
          <CustomButton
            color="#006DBE"
            bkColor="#99AFBD"
            btnText="Clear"
            width={170}
            handleClick={handleClear}
          />
        </div>
      </div>
    </div>
  )
}

export default CarWashResult
